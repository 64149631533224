import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue')
    },
    {
        path: '/',
        name: 'HomeOne',
        component: () => import(/* webpackChunkName: "home-one" */ '../views/HomeOne.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/service',
        name: 'Service',
        component: () => import(/* webpackChunkName: "service" */ '../views/Service.vue')
    },
    {
        path: '/service-details/:id',
        name: 'ServiceDetails',
        component: () => import(/* webpackChunkName: "service-details" */ '../views/ServiceDetails.vue')
    },
    {
        path: '/project-on-going',
        name: 'Project',
        component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue')
    },
    {
        path: '/project-on-going-details/:id',
        name: 'ProjectDetails',
        component: () => import(/* webpackChunkName: "project" */ '../views/ProjectDetails.vue')
    },
    {
        path: '/project-completed',
        name: 'ProjectCompleted',
        component: () => import('../views/ProjectCompleted.vue')
    },
    {
        path: '/project-details-completed/:id',
        name: 'ProjectDetailsCompleted',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/ProjectDetailsCompleted.vue')
    },
    {
        path: '/renovation-project',
        name: 'RenovationProject',
        component: () => import('../views/RenovationProject.vue')
    },
    {
        path: '/renovation-project-details/:id',
        name: 'RenovationProjectDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/RenovationProjectDetails.vue')
    },
    {
        path: '/mande-project-on-going',
        name: 'MAndEProject',
        component: () => import('../views/MAndEProject.vue')
    },
    {
        path: '/mande-project-on-going-details/:id',
        name: 'MAndEProjectDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/MAndEProjectDetails.vue')
    },
    {
        path: '/mande-project-completed',
        name: 'MAndEProjectCompleted',
        component: () => import('../views/MAndEProjectCompleted.vue')
    },
    {
        path: '/mande-project-completed-details/:id',
        name: 'MAndEProjectCompletedDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/MAndEProjectCompletedDetails.vue')
    },
    {
        path: '/renovation-mande-project',
        name: 'RenovationMAndEProject',
        component: () => import('../views/RenovationMAndEProject.vue')
    },
    {
        path: '/renovation-mande-project-details/:id',
        name: 'RenovationMAndEProjectDetails',
        component: () => import(/* webpackChunkName: "project-details" */ '../views/RenovationMAndEProjectDetails.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    {
        path: '/policy',
        name: 'Policy',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Policy.vue')
    },
    {
        path: '/csr',
        name: 'CSR',
        component: () => import(/* webpackChunkName: "contact" */ '../views/CSR.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
